import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoCard from 'components/Indoor_Cameras/IN-8015_HD/Usermanual/YoutubeVideoCard';
import NavButtons from 'components/Indoor_Cameras/IN-8015_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8015 Full HD Manual Lense Adjustment",
  "path": "/Indoor_Cameras/IN-8015_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8015 Full HD Indoor IP Camera Manual Lense Adjustment",
  "image": "./P_SearchThumb_IN-8015HD_Lense_Adjust.png",
  "social": "/images/Search/P_SearchThumb_IN-8015HD_Lense_Adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8015HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8015 Full HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-8015 Full HD Indoor IP Camera Manual Lense Adjustment' image='/images/Search/P_SearchThumb_IN-8015HD_Lense_Adjust.png' twitter='/images/Search/P_SearchThumb_IN-8015HD_Lense_Adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8015_HD/Objektivjustage/' locationFR='/fr/Indoor_Cameras/IN-8015_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8015-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8015-full-hd-manual",
        "aria-label": "in 8015 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8015 Full HD Manual`}</h2>
    <h3 {...{
      "id": "lense-adjustment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lense-adjustment",
        "aria-label": "lense adjustment permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lense Adjustment`}</h3>
    <YoutubeVideoCard mdxType="YoutubeVideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "adjust-the-focus-of-your-in-8015-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjust-the-focus-of-your-in-8015-full-hd",
        "aria-label": "adjust the focus of your in 8015 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust the Focus of your IN-8015 Full HD`}</h2>
    <p>{`The IN-8015 HD’s lense offers a limited field depth that was adjusted to a field between 1m to 8m. In case the object you want to observe lies outside of this area, you will have to adjust the lense accordingly.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "826px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2964e84a0e5545f39356f386f4eb7c5d/6a6e9/IN-8015_LenseAdjust.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABF0lEQVQoz22Sy07EMAxF+/+fwIIfmBV7hBCrWbJCzIZBYhag8ujQ5uHEzkUO4yhTsOSmsU+v7aQDAJRSmputY/2+xbq42dA2p5We9uDFgUuBiFTvP2gFiVCYwSfGuEGTORGCAOFhi7fNBcbnA0S4daawGjMjxojEjOljwufLoeWMG3LOCN4jAwiP95i215jev8BEZ+OrmHLEgji+Yry6xLjbIYfYuCqoDxUVZuQCBMpIxyPKalwVTClBSkHyDvPtBt93N5D4GzNuUJCIqijFWN/XF2PVNVd5Zbtj7blBq4YQKqjmvYdz7g+oYsZpLHhXWeNsrSP3XSq0LMu/HWq+ji1SuXmez7psv425Ctu59VV7cRPu2V7wBwhBwtA4LO/QAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2964e84a0e5545f39356f386f4eb7c5d/e4706/IN-8015_LenseAdjust.avif 230w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/d1af7/IN-8015_LenseAdjust.avif 460w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/a194c/IN-8015_LenseAdjust.avif 826w"],
              "sizes": "(max-width: 826px) 100vw, 826px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2964e84a0e5545f39356f386f4eb7c5d/a0b58/IN-8015_LenseAdjust.webp 230w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/bc10c/IN-8015_LenseAdjust.webp 460w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/40616/IN-8015_LenseAdjust.webp 826w"],
              "sizes": "(max-width: 826px) 100vw, 826px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2964e84a0e5545f39356f386f4eb7c5d/81c8e/IN-8015_LenseAdjust.png 230w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/08a84/IN-8015_LenseAdjust.png 460w", "/en/static/2964e84a0e5545f39356f386f4eb7c5d/6a6e9/IN-8015_LenseAdjust.png 826w"],
              "sizes": "(max-width: 826px) 100vw, 826px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2964e84a0e5545f39356f386f4eb7c5d/6a6e9/IN-8015_LenseAdjust.png",
              "alt": "IN-8015 Full HD Objektivjustage",
              "title": "IN-8015 Full HD Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Connect the camera to the power supply and the network cable. Open the web interface and use the included tool to turn the lense until you can see a clear and sharp picture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      